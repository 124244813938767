import * as React from 'react';

export const blocks = [
  {
    title: 'Arosa Lenzerheide',
    teaser: require('./images/blocks/Arosa_Lenzerheide_02.png'),
    subTitle: 'Von Bären und anderen Geniessern',
    subText:
      'Einmal abschalten bitte! In der Region Arosa Lenzerheide kann man wunderbar offline gehen. Vor grandioser Kulisse. ',
    audioTitle: 'Höre hier die Geschichte aus dem Globetrotter Magazin!',
    audioFile: require('./mp3/Arosa_klein.mp3'),
    slider: [
      require('./images/blocks/JPEG/ArosaLenzerheide/Slider/ArosaLenz_Slider_1.jpg'),
      require('./images/blocks/JPEG/ArosaLenzerheide/Slider/ArosaLenz_Slider_2.jpg'),
      require('./images/blocks/JPEG/ArosaLenzerheide/Slider/ArosaLenz_Slider_3.jpg'),
      require('./images/blocks/JPEG/ArosaLenzerheide/Slider/ArosaLenz_Slider_4.jpg'),
      require('./images/blocks/JPEG/ArosaLenzerheide/Slider/ArosaLenz_Slider_5.jpg'),
    ],
    tipsTitle: 'Schö’s Tägli!',
    tipsText: (
      <>
        In der einmaligen Landschaft von Arosa Lenzerheide <br />
        fällt es wirklich nicht schwer einen schönen Tag zu verbringen. Wir
        stellen euch zwei besonders tolle Wanderungen vor:
      </>
    ),
    footer: (
      <>
        Inspiration und tolle Wandertipps gibt es auf der Website der Region.{' '}
        <a
          href="https://arosalenzerheide.swiss"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mehr erfahren&nbsp;»
        </a>
      </>
    ),
    tips: [
      {
        image: require('./images/blocks/JPEG/ArosaLenzerheide/Tipps/ArosaLenz_Tipp_1.jpg'),
        title: 'Kulinarik Wanderung',
        text: (
          <>
            Im Zentrum dieser Wanderung stehen die Vielfalt der Natur und die
            Kulinarik auf den Alpbetrieben rund um Arosa. Vom höchsten Punkt,
            dem Hauptichopf (Maraner Hauptji), kann das herrliche Panorama ins
            Schanfigg, die südlichen Bündner Berge und Arosa genossen werden.
            Durch die günstige Lage erfreut sich die Tour bereits im Frühjahr
            reger Beliebtheit.
          </>
        ),
        link:
          'https://arosalenzerheide.swiss/de/Region/Arosa/Sommer/Wandern/Routen-und-Touren/768-Arosa-Ochsenalp-Rundtour_tour_18278123',
      },
      {
        image: require('./images/blocks/JPEG/ArosaLenzerheide/Tipps/ArosaLenz_Tipp_2.jpg'),
        title: 'Höhenweg Tgantieni',
        text: (
          <>
            Der Höhenweg Tgantieni – Piz Scalottas – Heidebüel ist eine Bergtour
            für trittfeste Wanderer. Stellenweise etwas ausgesetzt, für geübte
            Bergwanderer aber problemlos machbar. Die weitreichenden Ausblicke
            auf die gegenüberliegende Talseite, das Dorf Lenzerheide oder den
            Heinzenberg sind die Anstrengung wert! Nach einer Stärkung im
            Bergrestaurant Scalottas, genießt man im Anschluss die tollen
            Bergwiesen im Heidebüel noch mehr.
          </>
        ),
        link:
          'https://arosalenzerheide.swiss/de/Region/Lenzerheide/Sommer/Wandern/Wandertouren/GPS-Hoehenweg-Tgantieni-Piz-Scalottas-Heidbueel_tour_16881048',
      },
    ],
  },

  {
    title: 'Davos Klosters',
    teaser: require('./images/blocks/Davos_Klosters_01.png'),
    subTitle: 'Sommer aktiv',
    subText:
      'Die Bergwelt von Davos Klosters steckt voller Überraschungen. Vor allem, wenn man seine Wanderung im Winter beginnt und im Sommer beendet. Lausche unserer Geschichte aus dem Magazin!',
    audioTitle: 'Höre hier die Geschichte aus dem Globetrotter Magazin!',
    audioFile: require('./mp3/Davos_klein.mp3'),
    slider: [
      require('./images/blocks/JPEG/DavosKlosters/Slider/davos_slider_1.jpg'),
      require('./images/blocks/JPEG/DavosKlosters/Slider/davos_slider_2.jpg'),
      require('./images/blocks/JPEG/DavosKlosters/Slider/davos_slider_3.jpg'),
      require('./images/blocks/JPEG/DavosKlosters/Slider/davos_slider_4.jpg'),
      require('./images/blocks/JPEG/DavosKlosters/Slider/davos_slider_5.jpg'),
    ],
    tipsTitle: 'Wo die Walser wohnen',
    tipsText: (
      <>
        Die Region von Davos Klosters ist eine einmalige Kulturlandschaft. Das
        Zusammentreffen von grandioser Natur und Kulturraum beeindruckend. Hier
        sind zwei Wandertipps für euch:
      </>
    ),
    footer: (
      <>
        Ein tolles Wanderpackage für die Region Davos Klosters gibt es unter
        anderem beim Switzerland Travel Service. Mit dem Stichwort »Davos19«
        bekommen Globetrotter-Kunden 10% Rabatt!{' '}
        <a
          href="https://switzerlandtravelcenter.de/tours/wandererlebnis-davos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hier geht’s zum Angebot »
        </a>
      </>
    ),
    tips: [
      {
        image: require('./images/blocks/JPEG/DavosKlosters/Tipps/Davos_Tipp_1.jpg'),
        title: 'Fanezfurgga-Wanderung',
        text: (
          <>
            Auf dem Alpweg hinter Monstein geht’s hinauf, auf die Fanezmeder.
            Teilweise pfadlos geht es weiter, durch allmählich ansteigende,
            blumenreiche Bergwiesen in die Hochmulde des Bärentällis. Bald ist
            die Fanezfurgga erreicht. In östlicher Richtung zum ersten kleinen
            Bachlauf hinunter und weiter vorne, oberhalb dem Wasserfall wird das
            Wasser überquert. Ein kurzer Anstieg und dann auf steilem Pfad hinab
            auf die Endmoräne des ehemaligen Chüealpgletschers. In Sertig Dörfli
            ziehen der Wasserfall und das hübsche Kirchlein unsere Blicke auf
            sich. Eine faszinierende Gratwanderung zwischen Kultur- und
            Naturraum.
          </>
        ),
        link:
          'https://www.davos.ch/sommer/aktivitaeten/wandern/wandern-in-davos-klosters/wandertouren/touren/outdooractive/Outdooractive/fanezfurgga/',
      },
      {
        image: require('./images/blocks/JPEG/DavosKlosters/Tipps/Davos_Tipp_2.jpg'),
        title: 'Schlappiner Joch',
        text: (
          <>
            Wandern, wo einst Säumer gingen - Von Klosters aus gondelt man auf
            die Saaser Alp und wandert zum Zügenhüttli. Auf dem Höhenweg gelangt
            man über das Älpli zum Schlappiner Joch. Dieser Weg diente schon als
            Kriegsschauplatz vor und während dem Prättigauerkrieg 1622 zwischen
            Graubünden und dem Montafon. Aber auch viele Handelsgüter, wie der
            Wein aus dem Veltlin und Vieh wurden jährlich auf dem Saumpfad
            transportiert. Vom Joch aus führt ein steiler Abstieg bis Schlappin.
            Direkt am See in Schlappin befinden sich schöne Grillstellen. Über
            die Alpstrasse oder über den Fussweg auf der linken Talseite gelangt
            man an einem rauschenden Wildbach entlang zurück nach Klosters Dorf.
          </>
        ),
        link:
          'https://www.davos.ch/sommer/aktivitaeten/wandern/wandern-in-davos-klosters/wandertouren/touren/outdooractive/Outdooractive/793-schlappinerjoch/',
      },
    ],
  },

  {
    title: 'Engadin',
    teaser: require('./images/blocks/Engadin_02.png'),
    subTitle: 'Hoch und Hinaus',
    subText:
      'Strahlend weiß leuchten die Gletscher des Berninamassivs. Hier wandert man im Hochgebirge vor einem der schönsten Panoramen der Alpen. Höre hier die Geschichte zur Bernina Tour aus dem Globetrotter Magazin!',
    audioTitle: 'Höre hier die Geschichte aus dem Globetrotter Magazin!',
    audioFile: require('./mp3/bernina_klein.mp3'),
    slider: [
      require('./images/blocks/JPEG/Engadin/Slider/Engadin_Slider_1.jpg'),
      require('./images/blocks/JPEG/Engadin/Slider/Engadin_Slider_2.jpg'),
      require('./images/blocks/JPEG/Engadin/Slider/Engadin_Slider_3.jpg'),
      require('./images/blocks/JPEG/Engadin/Slider/Engadin_Slider_4.jpg'),
      require('./images/blocks/JPEG/Engadin/Slider/Engadin_Slider_5.jpg'),
    ],
    tipsTitle: 'Komm auf Touren!',
    tipsText: (
      <>
        Nicht nur die Bernina Tour ist eine Reise wert. Wir stellen zwei
        Alternativen in der Region Engadin vor. Wanderungen im »Garten des Inn«:
      </>
    ),
    footer: (
      <>
        Wenn du die Bernina Tour aus unserer Heftgeschichte selbst erleben
        willst, buchst du die Tour etwa bei Eurotrek.{' '}
        <a
          href="https://www.eurotrek.ch/de/aktivitaeten/wanderferien/schweiz/ch-bernina-tour-6-5"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hier geht’s zum Angebot »
        </a>
        .
      </>
    ),
    tips: [
      {
        image: require('./images/blocks/JPEG/Engadin/Tipps/Engadin_Tipp_1.jpg'),
        title: 'Via Engiadina',
        text: (
          <>
            Die fünf Etappen von Maloja bis nach Zernez führen durch Arven- und
            Lärchenwälder, über kleine Bäche mit kristallklarem Wasser, über
            Alpwiesen und vorbei an idyllischen Maiensässen und ursprünglichen
            Engadiner Dörfern. Ständiger Begleiter: der atemberaubende Blick
            über die weite Engadiner Landschaft mit ihrem klaren Himmel und den
            leuchtenden Gletschern.
          </>
        ),
        link: 'www.engadin.ch/via-engiadina/',
      },
      {
        image: require('./images/blocks/JPEG/Engadin/Tipps/Engadin_Tipp_2.jpg'),
        title: 'Muottas Muragl Panoramaweg',
        text: (
          <>
            Dieser Ausflug ist für Genießer! Die Anfahrt in der pittoresken
            Standseilbahn, die Aussicht über die Seenlandschaft und auf das
            Berninamassiv und schließlich der Panoramaweg, der gemütlich durch
            Föhrenwälder und vorbei an knorrigen Arvenwurzeln um den Schafberg
            führt. Auf halber Strecke liegt das kleine, gemütliche Restaurant
            Unterer Schafberg, welches zu einer Rast einlädt. Beim Anstieg auf
            die Alp Languard geraten wir doch noch ein wenig ins Schnaufen, was
            oben Anlass genug für ein Stück Kuchen ist. Und möglicherweise eine
            gute Ausrede, um der Bequemlichkeit nachzugeben und den Sessellift
            hinunter nach Pontresina zu nehmen.
          </>
        ),
        link:
          'https://www.engadin.ch/de/aktivitaeten/panoramaweg-muottas-muragl/',
      },
    ],
  },
];
