import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';

import { Block, Image } from './Block';
import { blocks } from './config';
import { Sweepstakes } from './Sweepstakes';
import { useAudioPlayer } from './useAudioPlayer';
import { useGoogleFonts } from './useGoogleFonts';

const fonts = ['Open+Sans+Condensed:300,700', 'Open+Sans:800'];

function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const onScroll = React.useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return scrollPosition;
}

export function App(props: {}) {
  const fontsLoaded = useGoogleFonts(fonts);
  const scrollY = useScrollPosition();
  const [state, sendPlayer] = useAudioPlayer();

  const commonStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  };

  const layer1 = useSpring({
    to: {
      ...commonStyle,
      transform: `translateY(${scrollY * 0}px)`,
    },
    config: { duration: 0 },
  });
  const layer2 = useSpring({
    to: {
      ...commonStyle,
      transform: `translateY(${scrollY * -0.1}px)`,
    },
    config: { duration: 0 },
  });
  const layer3 = useSpring({
    to: {
      ...commonStyle,
      transform: `translateY(${scrollY * -0.15}px)`,
    },
    config: { duration: 0 },
  });
  const layer4 = useSpring({
    to: {
      ...commonStyle,
      transform: `translateY(${scrollY * -0.2}px)`,
    },
    config: { duration: 0 },
  });
  const layer5 = useSpring({
    to: {
      ...commonStyle,
      transform: `translateY(${scrollY * -0.25}px)`,
    },
    config: { duration: 0 },
  });

  return (
    <>
      <Global
        styles={css`
          * {
            font-size: 16px;
            font-family: Open Sans Condensed;
          }

          a {
            color: #c35a58;
            text-decoration: none;
            font-size: 1em;

            :hover {
              text-decoration: underline;
            }
          }

          ::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background: transparent;
          }

          ::-webkit-scrollbar-track {
            background: #fff;
          }

          ::-webkit-scrollbar-thumb {
            background: #000;
            border-radius: 10px;
            border: 4px solid #fff;
          }

          @media (max-width: 700px) {
            * {
              font-size: 12px;
            }
          }
        `}
      />
      <Page ready={fontsLoaded}>
        <PageContent>
          <ParallaxContainer>
            <ParallaxLayer
              style={layer1}
              src={require('./images/parallax/Hintergrund.png')}
            />

            <ParallaxLayer
              style={layer2}
              src={require('./images/parallax/Bogen_4.png')}
            />
            <ParallaxLayer
              style={layer3}
              src={require('./images/parallax/Bogen_3.png')}
            />
            <ParallaxLayer
              style={layer4}
              src={require('./images/parallax/Bogen_2.png')}
            />
            <PageHeader>
              Schweizer
              <br /> Höhenrausch
            </PageHeader>
            <ParallaxLayer
              style={layer5}
              src={require('./images/parallax/Tannen.png')}
            />
          </ParallaxContainer>

          <div
            style={{
              backgroundColor: '#FFF',
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              height: '100vh',
              top: '120vw',
            }}
          />

          <ContentLayer>
            <ContentBody>
              <SubHeader>Durch die Zauberberge</SubHeader>
              <Teaser>
                Mach dich auf den Weg durch die Schweiz. Mit jedem Schritt durch
                die Berge Graubündens fühlst du dich freier. Wir haben ein paar
                der schönsten Wanderungen zusammengestellt. Nimm am Gewinnspiel
                teil und gewinne einen tollen Trip in die Berge. Außerdem
                verlosen wir coole Preise von Mammut und Victorinox.
              </Teaser>

              {blocks.map(block => (
                <Block
                  key={block.title}
                  sendPlayer={sendPlayer}
                  isPlaying={
                    state.matches('playing') &&
                    state.context.file === block.audioFile
                  }
                  {...block}
                />
              ))}

              <Image
                src={require('./images/blocks/Teilnahme.png')}
                alt="Teilnahme"
              />

              <Sweepstakes />

              <Footer
                src={require('./images/Footer.png')}
                role="presentation"
                alt=""
              />
            </ContentBody>
          </ContentLayer>
        </PageContent>
      </Page>
    </>
  );
}

const Page = styled.div<{ ready: boolean }>`
  visibility: ${props => (props.ready ? 'visible' : 'hidden')};
`;

const PageHeader = styled.h1`
  position: absolute;
  left: 2rem;
  right: 2rem;
  top: ${170 / 16}rem;

  font-family: Open Sans;
  font-weight: 800;
  font-size: 4rem;
  color: #c35a58;
  letter-spacing: 4.52px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    top: 5vw;
    z-index: 3;

    margin: 0 20px;

    font-size: 3.5rem;
  }
`;

const SubHeader = styled.h2`
  font-family: Open Sans;
  font-weight: 800;
  font-size: 3rem;
  color: #a54e4d;
  letter-spacing: 2.97px;
  text-align: center;
  line-height: 1.5em;
  text-transform: uppercase;

  ::after {
    display: block;
    content: '';
    background: no-repeat url(${require('./images/ZickZack.png')}) center;
    background-size: cover;
    height: 33px;
    width: 206px;
    margin: 0 auto;
  }

  @media (max-width: 1000px) {
    font-size: 2rem;

    ::after {
      height: 20px;
      margin-top: 5px;
    }
  }
`;

const PageContent = styled.div`
  position: relative;
  max-width: 1156px;
  margin: 0 auto;
  overflow-x: hidden;
`;

const ParallaxContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: 1156px;
  top: 0;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  transform: translateZ(0);
`;

const ParallaxLayer = styled(animated.div)<{ src: string }>`
  background-image: url(${props => props.src});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  height: 200vh;
  width: 100%;
`;

const ContentLayer = styled.div`
  position: relative;
  margin-top: 120vw;

  padding: 0 100px 0 100px;

  background: #fff url(${require('./images/pattern.png')});

  ::before {
    position: absolute;
    top: -20vw;
    left: 0;
    right: 0;

    padding-bottom: 100%;
    content: '';
    background-image: url(${require('./images/parallax/Bogen_1.png')});
    background-attachment: scroll;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  @media (max-width: 1000px) {
    padding-left: 20px;
    padding-right: 20px;

    ::before {
      padding-bottom: 110%;
      background-size: cover;
    }
  }
`;

const ContentBody = styled.div`
  position: relative;
`;

const Teaser = styled.p`
  font-weight: 700;
  font-size: 1rem;
  color: #ffffff;
  letter-spacing: 0.96px;
  text-align: center;
`;

const Footer = styled.img`
  display: block;
  width: calc(100% + 200px);
  margin: 0 -100px;
`;
