import styled from '@emotion/styled';
import * as React from 'react';

import { blocks } from './config';
import { useTransition, animated } from 'react-spring';

type Block = typeof blocks[0];
type Slides = typeof blocks[0]['slider'];

function Block_(props: Block & { isPlaying: boolean; sendPlayer: any }) {
  return (
    <>
      <Image src={props.teaser} alt={props.title} />
      <Connector height={70} />

      <BlockTitle>{props.subTitle}</BlockTitle>
      <BlockText>{props.subText}</BlockText>

      <Connector height={200} />
      <SlideContainer>
        <PlayArea>
          <PlayButton
            playing={props.isPlaying}
            onClick={() =>
              props.sendPlayer({ type: 'PLAY_PAUSE', file: props.audioFile })
            }
          >
            {props.audioTitle}
          </PlayButton>
        </PlayArea>

        <Slider slides={props.slider} />
      </SlideContainer>

      <Connector height={120} />

      <BlockTitle>{props.tipsTitle}</BlockTitle>
      <BlockText style={{ marginBottom: '2rem' }}>{props.tipsText}</BlockText>

      <Tip>
        <TipImage>
          <img src={props.tips[0].image} alt={props.tips[0].title} />
        </TipImage>
        <Column style={{ paddingLeft: 40 }}>
          <TipText position="right">
            <TipTitle>{props.tips[0].title}</TipTitle>
            {props.tips[0].text}
            <br />
            <a
              href={props.tips[0].link}
              rel="noopener noreferrer"
              target="_blank"
            >
              Mehr erfahren
            </a>
          </TipText>
        </Column>
      </Tip>

      <Tip>
        <Column style={{ paddingRight: 40, textAlign: 'right' }}>
          <TipText position="left">
            <TipTitle>{props.tips[1].title}</TipTitle>
            {props.tips[1].text}
            <br />
            <a
              href={props.tips[1].link}
              rel="noopener noreferrer"
              target="_blank"
            >
              Mehr erfahren
            </a>
          </TipText>
        </Column>
        <TipImage>
          <img src={props.tips[1].image} alt={props.tips[1].title} />
        </TipImage>
      </Tip>

      <Footer>{props.footer}</Footer>
    </>
  );
}

export function Slider(props: { slides: Slides }) {
  const [current, setCurrent] = React.useState(0);

  const transitions = useTransition(
    props.slides[current],
    slide => {
      return slide;
    },
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    },
  );

  return (
    <SliderBox>
      {transitions.map(({ item, props: style, key }, index) => (
        <Slide
          onClick={() => setCurrent((current + 1) % props.slides.length)}
          key={key}
          style={style}
          src={item}
        />
      ))}

      <Dots>
        {props.slides.map((_, index) => (
          <Dot
            active={current === index}
            onClick={() => setCurrent(index)}
            key={index}
          />
        ))}
      </Dots>
    </SliderBox>
  );
}

export const Block = React.memo(Block_);

export const Image = styled.img`
  display: block;
  margin: 5rem auto 1rem auto;
  width: 100%;
  max-width: 550px;

  @media (max-width: 1000px) {
    max-width: 350px;
  }
`;

export const BlockTitle = styled.h3`
  margin: 1rem 0;
  font-family: Open Sans;
  font-weight: 800;
  font-size: 2rem;
  color: #c35a58;
  letter-spacing: 1.6px;
  text-align: center;
  text-transform: uppercase;
`;

const BlockText = styled.div`
  font-weight: 700;
  font-size: 1rem;
  color: #676867;
  letter-spacing: 0.96px;
  text-align: center;
  margin: 0 0 1rem;
`;

const Connector = styled.div<{ height: number }>`
  margin: 0 auto;
  width: 0;
  height: ${props => props.height}px;
  border-left: 1px dashed #979797;

  @media (max-width: 1000px) {
    height: ${props => props.height / 2}px;
  }
`;

const SlideContainer = styled.div`
  position: relative;
  margin: 2rem 0;

  ::after {
    display: block;
    content: '';

    padding-bottom: 66.8402778%;
  }
`;

const SliderBox = styled.div`
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  cursor: url(${require('./images/cursor.png')}), auto;
`;

const Slide = styled(animated.img)`
  position: absolute;

  top: 0;
  left: 0;

  display: block;
  width: 100%;
`;

const Dots = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

const Dot = styled.div<{ active: boolean }>`
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 2px;
  border-radius: 50%;

  border: 1px solid #fff;
  background: ${props => (props.active ? '#fff' : undefined)};
`;

const PlayArea = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
`;

const PlayButton = styled.div<{ playing: boolean }>`
  position: relative;

  font-weight: 700;
  font-size: 1.3rem;
  color: #c35a58;
  letter-spacing: 0.82px;
  text-align: center;
  text-transform: uppercase;
  outline: 0;
  white-space: nowrap;
  cursor: pointer;

  ::before {
    position: absolute;
    top: 0;
    left: -97px;

    display: block;
    width: 87px;
    height: 87px;

    content: '';

    background: no-repeat
      url(${props =>
        props.playing
          ? require('./images/PauseButton.png')
          : require('./images/Playbutton.png')});
    background-size: 100%;
  }

  @media (max-width: 1000px) {
    white-space: normal;
    background: #fff;
  }
`;

const Tip = styled.div`
  clear: both;
  display: flex;
  margin-bottom: 3rem;

  ::before,
  ::after {
    display: table;
    content: '';
  }

  img {
    display: block;
    width: 100%;
  }
`;
const Column = styled.div`
  float: left;
  width: 45%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 55%;
  }
`;

const TipImage = styled.div`
  float: left;
  width: 55%;

  @media (max-width: 1000px) {
    width: 45%;
  }
`;

const TipTitle = styled.h4`
  color: #c35a58;
  letter-spacing: 0.96px;
  margin: 0;
`;

const TipText = styled.div<{ position: 'right' | 'left' }>`
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 1px dashed #979797;
    ${props => (props.position === 'right' ? 'left: -15px' : 'right: -15px')}
  }

  a {
    color: #c35a58;
    font-weight: 700;
  }
`;

const Footer = styled.div`
  font-weight: 700;
  position: relative;
  display: block;
  text-align: center;
  font-size: 1.4rem;
  color: #c35a58;
  letter-spacing: 0.82px;

  a {
    text-decoration: underline;
  }
`;
