import styled from '@emotion/styled';

import * as React from 'react';

const GridSize = 12;

interface PropsGrid {
  padding?: number;
  children: any;
  [prop: string]: any;
}

export default function Grid({ padding, ...props }: PropsGrid) {
  return <GridBlock style={{ padding }} {...props} />;
}

const GridBlock = styled.div`
  ::after,
  ::before {
    display: table;
    content: '';
    clear: both;
  }
`;

interface PropsRow {
  margin?: number;
  children: any;
  [prop: string]: any;
}

export function Row({ margin, ...props }: PropsRow) {
  return <Row_ style={{ marginBottom: margin }} {...props} />;
}

const Row_ = styled.div`
  ::before,
  ::after {
    display: table;
    content: '';
    clear: both;
  }
`;

interface PropsColumn {
  span: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  style?: {};
  [prop: string]: any;
}

export function Column({ span, style, ...props }: PropsColumn) {
  return (
    <Column_
      style={{ width: `${(span / GridSize) * 100}%`, ...style }}
      {...props}
    />
  );
}

const Column_ = styled.div`
  float: left;
  min-height: 1px;
  box-sizing: border-box;
`;
