import { useMachine } from '@xstate/react';
import { Machine } from 'xstate';

// interface Context {
//   player: HTMLAudioElement;
// }
// interface Schema {
//   states: {
//     stopped: {};
//     playing: {};
//     paused: {};
//   };
// }
// type Events =
//   | { type: 'PLAY'; file: string }
//   | { type: 'PAUSE' }
//   | { type: 'PLAY_PAUSE'; file: string };

const machine = Machine(
  {
    initial: 'stopped',

    context: { player: new Audio(), file: undefined },
    on: { PLAY: { target: 'playing', actions: ['setFile', 'play'] } },

    states: {
      playing: {
        on: {
          PAUSE: { target: 'paused', actions: ['pause'] },
          PLAY_PAUSE: [
            {
              target: 'paused',
              actions: ['pause'],
              cond: 'isSameFile',
            },
            {
              target: 'playing',
              actions: ['setFile', 'play'],
            },
          ],
        },
      },
      // No file loaded yet
      stopped: {
        on: { PLAY_PAUSE: { target: 'playing', actions: ['setFile', 'play'] } },
      },
      // Previous file has been paused
      paused: {
        on: { PLAY_PAUSE: { target: 'playing', actions: ['setFile', 'play'] } },
      },
    },
  },
  {
    actions: {
      play: (ctx: any) => {
        ctx.player.play();
      },
      pause: (ctx: any) => {
        ctx.player.pause();
      },
      setFile: (ctx: any, event: any) => {
        if (ctx.file !== event.file) {
          ctx.player.src = event.file;
          ctx.file = event.file;
        }
      },
    } as any,
    guards: {
      isSameFile: (ctx: any, event: any) => {
        return event.file === ctx.file;
      },
    },
  },
);

export function useAudioPlayer() {
  return useMachine(
    machine.withContext({
      player: new Audio(),
    }),
  );
}
