import { useMachine } from '@xstate/react';
import * as React from 'react';
import * as WebFont from 'webfontloader';
import { Machine } from 'xstate';

const fontLoadMachine = Machine({
  initial: 'pending',

  on: { RESET: 'pending' },

  states: {
    pending: {
      // Complete
      on: { COMPLETE: 'ready' },
      // Timeout
      after: { 1000: 'ready' },
    },
    ready: {},
  },
});

export function useGoogleFonts(list: string[]) {
  const [state, send] = useMachine(fontLoadMachine);

  React.useEffect(() => {
    send('RESET');

    WebFont.load({
      active: () => send('COMPLETE'),
      google: { families: list },
    });
  }, [list, send]);

  return state.matches('ready');
}
