import { css, Global } from '@emotion/core';
import styled from '@emotion/styled';
import cx from 'classnames';
import { fromPairs, keys, update } from 'ramda';
import * as React from 'react';

import { BlockTitle, Slider } from './Block';
import Grid, { Column, Row } from './Layout';
import * as theme from './Sweepstakes.module.css';

const isEmpty = (value: null | string) => value == null || value.length === 0;
const isFalse = (value: null | boolean) => value == null || value === false;

const questions = [
  {
    title:
      'Wie lautet der Name des ersten Bewohners in der Bären-WG im Arosa Bärenland?',
    answers: [
      { title: 'Nepu', value: 'nepu' },
      { title: 'Napa', value: 'napa' },
      { title: 'Napu', value: 'napu' },
    ],
  },

  {
    title:
      'Wie heißt das beliebte Ausflugsziel der Davoser Gäste, mit Wasserfall und Gebirgsblick?',
    answers: [
      { title: 'Sertig Dörfli', value: 'sertig_doerfli' },
      { title: 'Sertig Städtli', value: 'sertig_staedtli' },
      { title: 'Sertig Häusli', value: 'sertig häusli' },
    ],
  },

  {
    title:
      'Wie heißt der Wanderweg im Engadin, der im Magazin beschrieben wird?',
    answers: [
      { title: 'Bernina Tour', value: 'bernina_tour' },
      { title: 'Bernardino Tour', value: 'bernardino_tour' },
      { title: 'Bardolino Tour', value: 'bardolino_tour' },
    ],
  },
];

const infoQuestions = [
  {
    id: 'newsletter_globetrotter',
    text: 'Ich möchte den Newsletter von Globetrotter Ausrüstung bestellen.',
  },
  {
    id: 'newsletter_schweiz',
    text: 'Ich möchte den Newsletter von Schweiz Tourismus bestellen.',
  },
];

declare var process: any;

const publicUrl = process.env.PUBLIC_URL;
const apiUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000/gewinnspiel/teilnehmen/'
    : `${publicUrl}/gewinnspiel/teilnehmen/`;

interface Errors {
  [key: string]: string[];
}

interface State {
  form: {
    [key: string]: any;
  };
  loading: boolean;
  success: boolean;
  errors: any;
  answers: Array<undefined | number>;
}

function Logos() {
  return (
    <LogosBlock>
      <a href="https://www.davos.ch" rel="noopener noreferrer" target="_blank">
        <img src={require('./images/Logos/Davos.png')} alt="Davos" />
      </a>

      <a
        href="https://arosalenzerheide.swiss/de"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          src={require('./images/Logos/Lenzerheide.png')}
          alt="Lenzerheide"
        />
      </a>

      <a href="http://www.engadin.ch" rel="noopener noreferrer" target="_blank">
        <img src={require('./images/Logos/engadin.png')} alt="Engadin" />
      </a>

      <a
        href="https://www.myswitzerland.com/de/erlebnisse/sommer-herbst/wandern"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={require('./images/Logos/Schweiz.png')} alt="Schweiz" />
      </a>

      <a
        href="https://www.globetrotter.de/marken/mammut/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={require('./images/Logos/mammut.png')} alt="Mammut" />
      </a>

      <a
        href="https://www.victorinox.com/de/de"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={require('./images/Logos/Victorinox.png')} alt="Victorinox" />
      </a>

      <a
        href="https://www.myswitzerland.com/de-de/unterwegs.html"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={require('./images/Logos/SchweizTravel.png')} alt="Schweiz" />
      </a>

      <a
        href="http://www.verliebtindieschweiz.de/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={require('./images/Logos/bahn.png')} alt="Bahn" />
      </a>
    </LogosBlock>
  );
}

export class Sweepstakes extends React.Component<any, State> {
  state: State = {
    form: {},
    loading: false,
    success: false,
    errors: null,
    answers: [undefined, undefined, undefined],
  };

  handleChange = (field: string) => (event: any) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: event.target.value,
      },
    });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCheck = (field: string) => (event: any) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: event.target.checked,
      },
    });
  };

  handleAnswer = (question: number, answer: number, value: string) => () => {
    this.setState({
      answers: update(question, answer, this.state.answers),
      form: {
        ...this.state.form,
        [`question_${question + 1}`]: value,
      },
    });
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const fields = {
      ...this.state.form,
    };

    this.setState({
      loading: true,
      errors: null,
      success: false,
    });
    fetch(apiUrl, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(fields),
    }).then(
      response => {
        response.json().then(jsonResponse => {
          console.log('request processed:', jsonResponse);
          if (jsonResponse.status === 'failure') {
            // const errors = jsonResponse.errors as Errors;
            const errorKeys = keys(jsonResponse.errors);
            const errorPairs = errorKeys.map(
              // @ts-ignore
              (key: string): [string, boolean] => [key, true],
            );

            this.setState({
              loading: false,
              success: false,
              errors: fromPairs(errorPairs),
            });
          } else {
            this.setState({ loading: false, success: true });
          }
        });
      },
      response => {
        console.error('form error:', response);
      },
    );
  };

  render() {
    const { form, loading, success, errors } = this.state;

    const questionsAnswered = this.state.answers.every(
      answer => answer !== undefined,
    );

    const formIsValid =
      isEmpty(form.first_name) === false &&
      isEmpty(form.last_name) === false &&
      isEmpty(form.email) === false &&
      isFalse(form.terms) === false;

    return (
      <div className={theme.Page} onClick={event => event.stopPropagation()}>
        {/* <Link className={theme.Back} to="/">
          Zurück
        </Link> */}
        {/* <div className={theme.Vignette} /> */}
        <BlockTitle style={{ margin: '2rem 0' }}>
          Mitmachen und Gewinnen
        </BlockTitle>

        {success === false ? (
          <div className={theme.Content}>
            <form
              method="post"
              onSubmit={this.handleSubmit}
              className={theme.Form}
            >
              <p>
                Um am Gewinnspiel teilnehmen und die Reise in die Schweiz
                gewinnen zu können, musst Du nur die folgenden Fragen
                beantworten. Viel Glück dabei!
              </p>
              {questions.map((question, questionIndex) => (
                <div className={theme.Question} key={questionIndex}>
                  <h2>{question.title}</h2>

                  {question.answers.map((answer, answerIndex) => (
                    <label className={theme.FormCheckbox} key={answerIndex}>
                      <input
                        type="radio"
                        name={`question_${questionIndex + 1}`}
                        value={answer.value}
                        onChange={this.handleAnswer(
                          questionIndex,
                          answerIndex,
                          answer.value,
                        )}
                      />
                      {answer.title}
                    </label>
                  ))}
                </div>
              ))}
              <div
                className={cx(theme.FormContainer, {
                  [theme.Disabled]: !questionsAnswered,
                })}
              >
                <Grid>
                  <Row>
                    <Column span={2} style={{ minWidth: 100 }}>
                      <label className={theme.FormCheckbox}>
                        <input
                          disabled={loading}
                          type="radio"
                          value="hr"
                          name="title"
                          onChange={this.handleChange('title')}
                        />
                        Herr
                      </label>
                    </Column>
                    <Column span={2} style={{ minWidth: 100 }}>
                      <label className={theme.FormCheckbox}>
                        <input
                          disabled={loading}
                          type="radio"
                          value="fr"
                          name="title"
                          onChange={this.handleChange('title')}
                        />
                        Frau
                      </label>
                    </Column>
                    <Column span={2} style={{ minWidth: 100 }}>
                      <label className={theme.FormCheckbox}>
                        <input
                          disabled={loading}
                          type="radio"
                          value="divers"
                          name="title"
                          onChange={this.handleChange('title')}
                        />
                        divers*
                      </label>
                    </Column>
                  </Row>

                  <Row>
                    <input
                      disabled={loading}
                      className="InputText"
                      type="text"
                      placeholder="Nachname*"
                      onChange={this.handleChange('last_name')}
                    />
                  </Row>
                  <Row>
                    <input
                      disabled={loading}
                      className="InputText"
                      type="text"
                      placeholder="Vorname*"
                      onChange={this.handleChange('first_name')}
                    />
                  </Row>
                  <Row>
                    <Column span={10} style={{ paddingRight: 20 }}>
                      <input
                        disabled={loading}
                        className="InputText InputText__street"
                        type="text"
                        placeholder="Straße"
                        onChange={this.handleChange('street_name')}
                      />
                    </Column>
                    <Column span={2}>
                      <input
                        disabled={loading}
                        className="InputText InputText__no"
                        type="text"
                        placeholder="Nr."
                        onChange={this.handleChange('street_number')}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column span={4} style={{ paddingRight: 20 }}>
                      <input
                        disabled={loading}
                        className="InputText InputText__zip"
                        type="text"
                        placeholder="PLZ"
                        onChange={this.handleChange('zip_code')}
                      />
                    </Column>
                    <Column span={8}>
                      <input
                        disabled={loading}
                        className="InputText InputText__city"
                        type="text"
                        placeholder="Ort"
                        onChange={this.handleChange('city')}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <input
                      disabled={loading}
                      style={
                        errors != null && 'email' in errors
                          ? { outline: '1px solid red' }
                          : undefined
                      }
                      type="text"
                      placeholder="E-Mail-Adresse*"
                      onChange={this.handleChange('email')}
                    />
                  </Row>
                </Grid>

                <div className={theme.FormHint}>* Pflichtfeld</div>

                <label className={theme.FormCheckbox}>
                  <input
                    disabled={loading}
                    type="checkbox"
                    name="terms"
                    onChange={this.handleCheck('terms')}
                  />
                  Die{' '}
                  <a
                    href="https://www.globetrotter.de/service/agb/#gewinnspiele"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Teilnahmebedingungen
                  </a>{' '}
                  und{' '}
                  <a
                    href="https://www.globetrotter.de/service/agb/#datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Datenschutzbestimmungen
                  </a>{' '}
                  habe ich gelesen und erkenne ich an.*
                </label>

                {infoQuestions.map((question, index) => (
                  <React.Fragment key={index}>
                    <label className={theme.FormCheckbox} key={index}>
                      <input
                        disabled={loading}
                        type="checkbox"
                        name={question.id}
                        onChange={this.handleCheck(question.id)}
                      />
                      {question.text}
                    </label>
                  </React.Fragment>
                ))}

                <button
                  type="submit"
                  className={theme.Submit}
                  disabled={formIsValid === false || loading === true}
                >
                  Senden
                </button>
              </div>

              {/* <Link to="/" className={mobileTheme.TileBack}>
                Zurück
              </Link> */}
            </form>
          </div>
        ) : (
          <div className={theme.Content} style={{ marginBottom: 80 }}>
            <p>Vielen Dank für Deine Teilnahme.</p>

            {/* <CTA to="/" className={mobileTheme.TileBack}>
              Zurück
            </CTA> */}
          </div>
        )}

        <Grid>
          <Global
            styles={css`
              @media (max-width: 700px) {
                .conditions,
                .logos {
                  width: 100% !important;
                }
              }
            `}
          />
          <Column className="conditions" span={12}>
            <Conditions style={{ margin: '2rem 0 4rem' }}>
              Verlost werden 6 Übernachtungen inklusive Frühstück in Graubünden
              für zwei Personen (2 Übernachtungen im »Guarda Val« in
              Lenzerheide, 2 Übernachtungen im »Hard Rock Hotel« in Davos sowie
              2 Übernachtungen im Hotel »Berninahaus« in Pontresina). Anreise
              erfolgt erster Klasse mit der Deutschen Bahn. Die Gewinner
              erhalten für die Transfers in der Schweiz je einen
              8-Tage-Swiss-Travel-Pass für die erste Klasse des Swiss Travel
              System. Als zweiten bis zehnten Preis verlosen wir coole
              Ausrüstungsstücke von{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.mammut.com/de/de/"
              >
                Mammut
              </a>{' '}
              (den Trion Spine zum Beispiel und den Seon Transporter) und
              Spartan-Wood-Taschenmesser von{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.victorinox.com/de/de"
              >
                Victorinox
              </a>
              . Teilnahmeschluss ist der 30. Juni 2019. Der Gewinner wird
              schriftlich benachrichtigt. Der Rechtsweg ist ausgeschlossen. Die
              Reise ist nicht übertragbar.
            </Conditions>
          </Column>
        </Grid>

        <AutoCollapse>
          <Column span={6} style={{ paddingRight: 20 }}>
            <SliderBox>
              <Slider
                slides={[
                  require('./images/blocks/JPEG/Gewinn_Footer/Hotelansicht.jpg'),
                  require('./images/blocks/JPEG/Gewinn_Footer/Hotelansicht_2.jpg'),
                  require('./images/blocks/JPEG/Gewinn_Footer/Hotelansicht_3.jpg'),
                ]}
              />
            </SliderBox>
          </Column>
          <Column span={6}>
            <div>
              Das Guarda Val ist ein einzigartiges Maiensässhotel, das sich auf
              elf bis zu 300 Jahre alte Hütten und Ställe verteilt. Im Weiler
              Sporz, direkt oberhalb der Lenzerheide auf 1600 Meter über Meer,
              verbindet es die Faszination gewachsener Bündner Alptradition mit
              Luxus, Genuss und herzlichem Service. Hier erwarten die Gewinner
              zusätzlich zwei 3-Gänge-Menüs! Coole Vibes spürt man im Hard Rock
              Hotel Davos. Hier kann man sich eine hauseigene Gitarre zum
              Schlafzimmer-Jam ausleihen. Historisch authentisch logiert man
              stattdessen auf 2046 Höhe im Berninahaus. Willkommen in der
              Schweiz!
            </div>

            <p>
              <a
                href="https://guardaval.ch/"
                rel="noopener noreferrer"
                target="_blank"
              >
                guardaval.ch
              </a>
              <br />
              <a
                href="https://hardrockhoteldavos.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                hardrockhoteldavos.com
              </a>
              <br />
              <a
                href="https://berninahaus.ch/"
                rel="noopener noreferrer"
                target="_blank"
              >
                berninahaus.ch
              </a>
            </p>
          </Column>
        </AutoCollapse>

        <ShopTeaser>
          <BlockTitle>Mehr Schweiz-Infos im Web</BlockTitle>

          <SocialIcon
            title="Facebook"
            src={require('./images/fb.png')}
            href="https://www.facebook.com/MySwitzerlandDE/"
          />
          <SocialIcon
            title="Twitter"
            src={require('./images/Twitter.png')}
            href="https://twitter.com/myswitzerland_d"
          />
          <SocialIcon
            title="Instagram"
            src={require('./images/insta.png')}
            href="https://www.instagram.com/myswitzerland/"
          />

          <aside>
            Telefonische Beratung: 00800 100 200 30
            <br />
            (kostenfrei, lokale Gebühren können anfallen)
          </aside>
        </ShopTeaser>

        <BlockTitle>Save the Date!</BlockTitle>

        <AutoCollapse>
          <Column span={6} style={{ paddingRight: 20 }}>
            <TImage src={require('./images/Freiluft.jpg')} alt="Guarda Val" />
          </Column>
          <Column span={6}>
            <Global
              styles={css`
                .freiluftLogo {
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                  margin-right: 1rem;
                }
              `}
            />

            <div>
              Grüezi!
              <br />
              Die Schweiz ist zu Gast beim{' '}
              <a
                href="https://www.globetrotter.de/veranstaltungen/stuttgart/freiluft-das-outdoor-testival-2/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Freiluft Testival
              </a>{' '}
              in Stuttgart! Am 01. und 02. Juni begrüßen wir euch auf dem
              Gelände des Reitstadions am Cannstatter Wasen. An unserem
              Schweiz-Stand könnt ihr euch über das Wanderparadies informieren
              und unseren Experten Löcher in den Bauch fragen. Bei einem
              spaßigen Schweiz-Quiz verlosen wir coole Gewinne von{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.mammut.com/de/de/"
              >
                Mammut
              </a>{' '}
              und
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.victorinox.com/de/de"
              >
                Victorinox
              </a>
              . Und natürlich kochen wir mit euch Schweizer Käsefondue. Stilecht
              auf Kochern von Primus. Wir freuen uns auf euch!
              <br />
              <img
                className="freiluftLogo"
                src={require('./images/kaese.png')}
                alt="Käse aus der Schweiz"
                height="72"
              />
              <img
                className="freiluftLogo"
                src={require('./images/PRIMUS.png')}
                alt="PRIMUS"
                height="36"
              />
            </div>
          </Column>
        </AutoCollapse>

        <ShopTeaser>
          <p>Hier findest du alles für deinen Schweiz-Trip!</p>
          <ShopLink
            href="https://www.globetrotter.de/reiseartikel/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Zum Shop &raquo;
          </ShopLink>
        </ShopTeaser>

        {/* <Conditions>
                Fotos: © Crown Copyright (2019) Visit Wales
              </Conditions> */}

        <Logos />
      </div>
    );
  }
}

const Conditions = styled.p`
  a {
    color: unset;
  }
`;

const ShopTeaser = styled.div`
  padding: 3rem 0;
  border-top: 1px dashed #979797;
  border-bottom: 1px dashed #979797;

  text-align: center;
  font-weight: 700;
  text-transform: uppercase;

  p {
    margin-top: 0;

    font-family: Open Sans;
    font-weight: 800;
    font-size: 1.5rem;
    color: #c35a58;
    letter-spacing: 4.73px;
  }

  aside {
    font-weight: bold;
    font-size: 1rem;
    color: #000000;
    letter-spacing: 4.73px;
    text-align: center;
    text-transform: uppercase;
  }
`;

const ShopLink = styled.a`
  clear: both;
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;

  font-size: 1.5rem;

  background: #c35a58;
  padding: 0 10px;
  line-height: 2.5rem;
  color: #fff;
  letter-spacing: 4.73px;
  text-transform: uppercase;
  text-decoration: none;

  em {
    text-decoration: underline;
    font-family: Open Sans;
    font-weight: 800;
    font-size: 3rem;
    font-style: normal;
    color: #3c434c;
  }

  @media (max-width: 700px) {
    font-size: 2rem;
    line-height: 2.3rem;
    letter-spacing: 0.2rem;
  }
`;

const LogosBlock = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  text-align: center;

  > a {
    display: inline-block;
    margin-bottom: 1rem;
    margin-left: 1.4rem;
    margin-right: 1.4rem;

    img {
      max-width: 160px;
    }
  }
`;

const TImage = styled.img`
  width: 100%;
`;

function SocialIcon(props: { src: string; href: string; title: string }) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'inline-block', margin: '2rem 1rem' }}
    >
      <IconImg src={props.src} alt={props.title} />
    </a>
  );
}

const IconImg = styled.img`
  width: 40px;
`;

const AutoCollapse = styled(Grid)`
  @media (max-width: 600px) {
    > div {
      width: 100% !important;
      float: none !important;
      padding-right: 0 !important;
      margin-bottom: 1rem;
    }
  }
`;

const SliderBox = styled.div`
  position: relative;
  padding-bottom: 66.74%;
`;
